// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabBar_tabBar__BftFb {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .TabBar_tabItem__SSZzC {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    height: 100%;
    cursor: pointer;
  }
  
  .TabBar_icon__RdLdn {
    font-size: 20px;
    margin-bottom: 2px;
  }
  
  .TabBar_title__4IRvS {
    font-size: 12px;
    color: #666;
  }
  
  .TabBar_active__Bjycm {
    color: #1890ff;
  }
  
  .TabBar_active__Bjycm .TabBar_title__4IRvS {
    color: #1890ff;
  }`, "",{"version":3,"sources":["webpack://./src/components/TabBar.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,SAAO;IACP,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".tabBar {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 50px;\n    background-color: #ffffff;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);\n  }\n  \n  .tabItem {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    height: 100%;\n    cursor: pointer;\n  }\n  \n  .icon {\n    font-size: 20px;\n    margin-bottom: 2px;\n  }\n  \n  .title {\n    font-size: 12px;\n    color: #666;\n  }\n  \n  .active {\n    color: #1890ff;\n  }\n  \n  .active .title {\n    color: #1890ff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabBar": `TabBar_tabBar__BftFb`,
	"tabItem": `TabBar_tabItem__SSZzC`,
	"icon": `TabBar_icon__RdLdn`,
	"title": `TabBar_title__4IRvS`,
	"active": `TabBar_active__Bjycm`
};
export default ___CSS_LOADER_EXPORT___;
