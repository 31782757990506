import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className="profile-header">
      <h1>PROFILE</h1>
      <div className="notification-icon">
        <span className="notification-dot"></span>
        <svg /* 铃铛图标的 SVG */ />
      </div>
    </div>
  );
};

export default Header;