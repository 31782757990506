import React from 'react';
import './QuickAction.css';

const QuickAction = () => {
  return (
    <div className="quick-actions">
      <div className="action-item">
        <div className="action-icon history">L</div>
        <span>History</span>
      </div>
      <div className="action-item">
        <div className="action-icon favorites">♥</div>
        <span>Favorites</span>
      </div>
      <div className="action-item">
        <div className="action-icon reminders">+</div>
        <span>Reminders</span>
      </div>
    </div>
  );
};

export default QuickAction;