// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 0;
  width: 100%; /* 确保容器占据整行 */
  border: 2px solid #353535; /* 四周都添加灰色粗边框 */
  margin: 0; /* 移除所有外边距 */
}
  
  .pagination-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #ffffff;
    color: #333;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .pagination-button:hover:not(:disabled) {
    background: #f5f5f5;
    border-color: #1890ff;
    color: #1890ff;
  }
  
  .pagination-button:disabled {
    background: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  
  .page-info {
    font-size: 14px;
    color: #666;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Category/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,MAAM;EACN,WAAW,EAAE,aAAa;EAC1B,yBAAyB,EAAE,eAAe;EAC1C,SAAS,EAAE,YAAY;AACzB;;EAEE;IACE,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":[".pagination {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px;\n  gap: 0;\n  width: 100%; /* 确保容器占据整行 */\n  border: 2px solid #353535; /* 四周都添加灰色粗边框 */\n  margin: 0; /* 移除所有外边距 */\n}\n  \n  .pagination-button {\n    padding: 8px 16px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    background: #ffffff;\n    color: #333;\n    cursor: pointer;\n    transition: all 0.3s;\n  }\n  \n  .pagination-button:hover:not(:disabled) {\n    background: #f5f5f5;\n    border-color: #1890ff;\n    color: #1890ff;\n  }\n  \n  .pagination-button:disabled {\n    background: #f5f5f5;\n    color: #999;\n    cursor: not-allowed;\n  }\n  \n  .page-info {\n    font-size: 14px;\n    color: #666;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
