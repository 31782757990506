import React from 'react';
import Header from './components/Header/Header';
import UserCard from './components/UserCard/UserCard';
import QuickAction from './components/QuickAction/QuickAction';
import MenuList from './components/MenuList/MenuList';
import Advertisement from './components/Advertisement/Advertisement';
import './Profile.css';

const Profile = () => {
  return (
    <div className="profile-page">
      <Header />
      <UserCard />
      <QuickAction />
      <MenuList />
      <Advertisement />
    </div>
  );
};

export default Profile;