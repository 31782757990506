// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-detail {
    flex: 1 1;
    overflow-y: auto;
    padding: 15px;
  }
  
  .detail-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .detail-item {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .detail-item h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: #333;
  }
  
  .detail-item p {
    margin: 0;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Category/components/ChannelContent/ChannelContent.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,SAAS;IACT,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB","sourcesContent":[".channel-detail {\n    flex: 1;\n    overflow-y: auto;\n    padding: 15px;\n  }\n  \n  .detail-list {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n  }\n  \n  .detail-item {\n    padding: 15px;\n    background: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .detail-item h3 {\n    margin: 0 0 10px 0;\n    font-size: 16px;\n    color: #333;\n  }\n  \n  .detail-item p {\n    margin: 0;\n    font-size: 14px;\n    color: #666;\n    line-height: 1.5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
