import React from 'react';
import styles from './TabBar.module.css';

const TabBar = ({ activeTab, onTabChange }) => {
  const tabs = [
    { id: 0, title: 'Live TV', icon: '🏠' },
    { id: 1, title: 'Guide', icon: '📑' },
    { id: 2, title: 'Movies&Series', icon: '💬' },
    { id: 3, title: 'Profile', icon: '👤' }
  ];

  return (
    <div className={styles.tabBar}>
      {tabs.map(tab => (
        <div
          key={tab.id}
          className={`${styles.tabItem} ${activeTab === tab.id ? styles.active : ''}`}
          onClick={() => onTabChange(tab.id)}
        >
          <span className={styles.icon}>{tab.icon}</span>
          <span className={styles.title}>{tab.title}</span>
        </div>
      ))}
    </div>
  );
};

export default TabBar;
