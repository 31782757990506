// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-list {
    margin: 20px;
    background: #222;
    border-radius: 15px;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #333;
  }
  
  .menu-item:last-child {
    border-bottom: none;
  }
  
  .menu-icon {
    margin-right: 15px;
  }
  
  .arrow {
    margin-left: auto;
    color: #666;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Profile/components/MenuList/MenuList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb","sourcesContent":[".menu-list {\n    margin: 20px;\n    background: #222;\n    border-radius: 15px;\n  }\n  \n  .menu-item {\n    display: flex;\n    align-items: center;\n    padding: 15px 20px;\n    border-bottom: 1px solid #333;\n  }\n  \n  .menu-item:last-child {\n    border-bottom: none;\n  }\n  \n  .menu-icon {\n    margin-right: 15px;\n  }\n  \n  .arrow {\n    margin-left: auto;\n    color: #666;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
