export function register() {
    // 检查浏览器是否支持 Service Worker 且是否在生产环境
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
      // 等待页面加载完成后再注册 Service Worker
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  
        navigator.serviceWorker
          .register(swUrl)
          .then((registration) => {
            // Service Worker 注册成功
            console.log('ServiceWorker registration successful:', registration);
            
            // 监听 Service Worker 更新
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (installingWorker == null) {
                return;
              }
              // 监听 Service Worker 状态变化
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    // 有新版本可用
                    console.log('新内容可用，请刷新页面');
                  } else {
                    // 首次安装完成
                    console.log('内容已缓存，可以离线使用');
                  }
                }
              };
            };
          })
          .catch((error) => {
            console.error('Service Worker 注册失败:', error);
          });
      });
    }
  }
  
  // 取消注册 Service Worker 的函数
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }