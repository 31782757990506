import React from 'react';
import './AppName.css';

const AppName = ({ name }) => {
  return (
    <div className="app-name-section">
      <div className="app-name">{name}</div>
    </div>
  );
};

export default AppName;