// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page {
    min-height: 100vh;
    background-color: #1a1a1a;
    padding: 20px;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;IACb,YAAY;EACd","sourcesContent":[".profile-page {\n    min-height: 100vh;\n    background-color: #1a1a1a;\n    padding: 20px;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
