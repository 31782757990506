import React from 'react';
import './ChannelContent.css';

const ChannelDetail = ({ channelId }) => {
  // 模拟频道详情数据
  const getChannelDetails = (id) => {
    return [
      { id: 1, title: `详情 ${id}-1`, content: '内容描述...' },
      { id: 2, title: `详情 ${id}-2`, content: '内容描述...' },
      { id: 3, title: `详情 ${id}-3`, content: '内容描述...' },
    ];
  };

  const details = getChannelDetails(channelId);

  return (
    <div className="channel-detail">
      <div className="detail-list">
        {details.map(detail => (
          <div key={detail.id} className="detail-item">
            <h3>{detail.title}</h3>
            <p>{detail.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChannelDetail;