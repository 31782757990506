// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-wrapper {
  width: 25%;
  background: #000;
  overflow: hidden;  /* 防止滚动溢出 */
}

.category-scroll {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;  /* iOS 流畅滚动 */
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.category-item {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s;
  background: #000;
  color: #fff;
}

.category-item.active {
  color: #ff4d4f;  /* 选中时文字变红 */
  background: #1a1a1a;  /* 选中时背景变灰 */
}

.category-item:hover {
  background: #1a1a1a;
}

/* 隐藏 Webkit 浏览器的滚动条 */
.category-scroll::-webkit-scrollbar {
  display: none;
}

.category-scroll::-webkit-scrollbar-track {
  background: #000;
}

.category-scroll::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 2px;
}

.category-scroll::-webkit-scrollbar-thumb:hover {
  background: #444;
}

/* 适配移动端 */
@media (max-width: 768px) {
  .category-wrapper {
    width: 30%;
  }

  .category-item {
    padding: 12px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/CategoryList/CategoryList.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB,GAAG,WAAW;AAChC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,iCAAiC,GAAG,aAAa;EACjD,qBAAqB,GAAG,YAAY;EACpC,wBAAwB,GAAG,gBAAgB;AAC7C;;AAEA;EACE,aAAa;EACb,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc,GAAG,YAAY;EAC7B,mBAAmB,GAAG,YAAY;AACpC;;AAEA;EACE,mBAAmB;AACrB;;AAEA,sBAAsB;AACtB;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,UAAU;AACV;EACE;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,eAAe;EACjB;AACF","sourcesContent":[".category-wrapper {\n  width: 25%;\n  background: #000;\n  overflow: hidden;  /* 防止滚动溢出 */\n}\n\n.category-scroll {\n  height: 100%;\n  overflow-y: auto;\n  -webkit-overflow-scrolling: touch;  /* iOS 流畅滚动 */\n  scrollbar-width: none;  /* Firefox */\n  -ms-overflow-style: none;  /* IE and Edge */\n}\n\n.category-item {\n  padding: 15px;\n  cursor: pointer;\n  transition: all 0.3s;\n  background: #000;\n  color: #fff;\n}\n\n.category-item.active {\n  color: #ff4d4f;  /* 选中时文字变红 */\n  background: #1a1a1a;  /* 选中时背景变灰 */\n}\n\n.category-item:hover {\n  background: #1a1a1a;\n}\n\n/* 隐藏 Webkit 浏览器的滚动条 */\n.category-scroll::-webkit-scrollbar {\n  display: none;\n}\n\n.category-scroll::-webkit-scrollbar-track {\n  background: #000;\n}\n\n.category-scroll::-webkit-scrollbar-thumb {\n  background: #333;\n  border-radius: 2px;\n}\n\n.category-scroll::-webkit-scrollbar-thumb:hover {\n  background: #444;\n}\n\n/* 适配移动端 */\n@media (max-width: 768px) {\n  .category-wrapper {\n    width: 30%;\n  }\n\n  .category-item {\n    padding: 12px;\n    font-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
