// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-list {
  width: 16.666%;
  background: #000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.channel-item {
  display: flex;
  justify-content: center;  /* 水平居中 */
  padding: 15px 0;
  color: #fff;
  -webkit-user-select: none;
          user-select: none;
  border-bottom: 1px solid #333;
  width: 100%;
  box-sizing: border-box;
}

.channel-item:last-child {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Category/components/ChannelSidebar/ChannelSidebar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,uBAAuB,GAAG,SAAS;EACnC,eAAe;EACf,WAAW;EACX,yBAAiB;UAAjB,iBAAiB;EACjB,6BAA6B;EAC7B,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".channel-list {\n  width: 16.666%;\n  background: #000;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.channel-item {\n  display: flex;\n  justify-content: center;  /* 水平居中 */\n  padding: 15px 0;\n  color: #fff;\n  user-select: none;\n  border-bottom: 1px solid #333;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.channel-item:last-child {\n  border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
