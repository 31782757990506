import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

const VideoSection = ({ streamUrl }) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  useEffect(() => {
    const initPlayer = () => {
      if (!videoRef.current) return;

      // Check if HLS.js is supported
      if (Hls.isSupported()) {
        console.log('HLS is supported, initializing HLS.js');
        // If an HLS instance already exists, destroy it
        if (hlsRef.current) {
          hlsRef.current.destroy();
        }

        const hls = new Hls({
          enableWorker: true,
          lowLatencyMode: true,
          manifestLoadingTimeout: 10000, // Set timeout for manifest loading
          manifestLoadingMaxRetry: 3, // Retry attempts for manifest loading
          levelLoadingTimeout: 10000, // Timeout for level loading
          fragLoadingTimeout: 20000, // Timeout for fragment loading
        });

        hls.loadSource(streamUrl);
        hls.attachMedia(videoRef.current);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log('Manifest parsed successfully, starting playback');
          setRetryCount(0); // Reset retry count
          videoRef.current.play().catch((error) => {
            console.log('Auto-play failed:', error);
          });
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error('HLS error:', data);
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.log(`Network error, retry attempt: ${retryCount + 1}`);
                if (retryCount < MAX_RETRIES) {
                  setTimeout(() => {
                    hls.startLoad();
                    setRetryCount((prev) => prev + 1);
                  }, 1000 * (retryCount + 1)); // Incremental retry delay
                } else {
                  console.error('Max retry attempts reached, stopping retries');
                  hls.destroy();
                }
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.log('Attempting to recover from media error');
                hls.recoverMediaError();
                break;
              default:
                console.error('Fatal error, destroying HLS instance');
                hls.destroy();
                break;
            }
          }
        });

        hlsRef.current = hls;
      } 
      // Native HLS support (Safari)
      else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        console.log('Native HLS support detected (Safari)');
        videoRef.current.src = streamUrl;
      }
    };

    initPlayer();

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
      setRetryCount(0);
    };
  }, [retryCount, streamUrl]);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        controls
        playsInline
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default VideoSection;
