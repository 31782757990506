// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-search {
  width: 100%;
  padding: 15px 16px; /* 与 ProjectList 相同的左右边距 */
  background: #000000;
  box-sizing: border-box;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  
}

.search-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.search-input {
  width: 100%;
  height: 40px;
  padding: 0 16px 0 40px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  font-size: 14px;
  background: #f5f5f5;
  transition: all 0.3s;
  box-sizing: border-box;
}

.search-input::placeholder {
  color: #999;
}

.search-input:focus {
  outline: none;
  border-color: #1890ff;
  background: #fff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/Message/components/MessageSearch/MessageSearch.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB,EAAE,0BAA0B;EAC9C,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;;AAErB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,gBAAgB;EAChB,6CAA6C;AAC/C","sourcesContent":[".message-search {\n  width: 100%;\n  padding: 15px 16px; /* 与 ProjectList 相同的左右边距 */\n  background: #000000;\n  box-sizing: border-box;\n}\n\n.search-input-wrapper {\n  position: relative;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  \n}\n\n.search-icon {\n  position: absolute;\n  left: 16px;\n  top: 50%;\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n\n.search-input {\n  width: 100%;\n  height: 40px;\n  padding: 0 16px 0 40px;\n  border: 1px solid #e8e8e8;\n  border-radius: 20px;\n  font-size: 14px;\n  background: #f5f5f5;\n  transition: all 0.3s;\n  box-sizing: border-box;\n}\n\n.search-input::placeholder {\n  color: #999;\n}\n\n.search-input:focus {\n  outline: none;\n  border-color: #1890ff;\n  background: #fff;\n  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
