import React from 'react';
import './ProjectDetail.css';

const ProjectDetail = ({ project }) => {
  if (!project) {
    return (
      <div className="project-detail-empty">
        <p>请选择一个项目查看详情</p>
      </div>
    );
  }

  // 示例数据
  const sections = [
    {
      id: 1,
      title: 'Top popular',
      items: [
        { id: 1, title: 'Item 1', image: 'path/to/image1' },
        { id: 2, title: 'Item 2', image: 'path/to/image2' },
        { id: 3, title: 'Item 3', image: 'path/to/image3' },
      ]
    },
    {
      id: 2,
      title: 'In theaters',
      items: [
        { id: 4, title: 'Item 4', image: 'path/to/image4' },
        { id: 5, title: 'Item 5', image: 'path/to/image5' },
        { id: 6, title: 'Item 6', image: 'path/to/image6' },
        // 更多项目...
      ]
    },
    {
      id: 3,
      title: 'New Releases',
      items: [
        { id: 7, title: 'Item 7', image: 'path/to/image7' },
        { id: 8, title: 'Item 8', image: 'path/to/image8' },
        { id: 9, title: 'Item 9', image: 'path/to/image9' },
        // 更多项目...
      ]
    },
    {
      id: 4,
      title: 'New Releases',
      items: [
        { id: 7, title: 'Item 7', image: 'path/to/image7' },
        { id: 8, title: 'Item 8', image: 'path/to/image8' },
        { id: 9, title: 'Item 9', image: 'path/to/image9' },
        // 更多项目...
      ]
    },
  ];

  return (
    <div className="project-detail">
      {sections.map(section => (
        <div key={section.id} className="detail-section">
          <div className="section-header">
            <h3 className="section-title">{section.title}</h3>
            <button className="see-more-btn">See more</button>
          </div>
          <div className="section-content">
            <div className="items-scroll-container">
              <div className="items-grid">
                {section.items.map(item => (
                  <div key={item.id} className="item-card">
                    <div className="item-image">
                      {/* 添加实际的图片 */}
                      <img 
                        src={item.image || 'https://via.placeholder.com/300x200'} 
                        alt={item.title}
                        className="actual-image"
                      />
                    </div>
                    <div className="item-title">{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectDetail;