import React, { useState } from 'react';
import './Search.css';

const Search = ({ onBack }) => {

  const [activeType, setActiveType] = useState(0);

  return (
    <div className="search-page">
      <div className="search-header">
        <div className="input-wrapper">
          <svg 
            className="search-icon" 
            width="16" 
            height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" 
              stroke="#fff" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
            <path 
              d="M21 21L16.65 16.65" 
              stroke="#fff" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
          <input 
            type="text" 
            className="search-input" 
            autoFocus  // 自动聚焦
          />
        </div>
        <button className="back-button" onClick={onBack}>Cancel</button>
      </div>
      <div className="search-types">
        <button 
          className={`type-button ${activeType === 0 ? 'active' : ''}`}
          onClick={() => setActiveType(0)}
        >
          LIVE TV
        </button>
        <button 
          className={`type-button ${activeType === 1 ? 'active' : ''}`}
          onClick={() => setActiveType(1)}
        >
          MOVIES&SERIES
        </button>
        <button 
          className={`type-button ${activeType === 2 ? 'active' : ''}`}
          onClick={() => setActiveType(2)}
        >
          PROGRAM
        </button>
      </div>
    </div>
  );
};

export default Search;