import React from 'react';
import './Advertisement.css';

const Advertisement = () => {
  return (
    <div className="advertisement">
      <img 
        src="/path-to-ad-image.jpg" 
        alt="Big Brother Mzansi season"
        className="ad-image"
      />
    </div>
  );
};

export default Advertisement;