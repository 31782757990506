// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-section {
  padding: 12px 15px;
  background-color: #000;
}

.search-bar {
  position: relative;
  background-color: #333;
  padding: 8px 15px 8px 40px;  /* 增加左边距给图标留位置 */
  border-radius: 20px;
  color: #999;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/SearchBar/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,0BAA0B,GAAG,gBAAgB;EAC7C,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,YAAY;AACd","sourcesContent":[".search-section {\n  padding: 12px 15px;\n  background-color: #000;\n}\n\n.search-bar {\n  position: relative;\n  background-color: #333;\n  padding: 8px 15px 8px 40px;  /* 增加左边距给图标留位置 */\n  border-radius: 20px;\n  color: #999;\n  font-size: 14px;\n}\n\n.search-icon {\n  position: absolute;\n  left: 12px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 16px;\n  height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
