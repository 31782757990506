import React from 'react';
import './ProjectList.css';

const ProjectList = ({ searchText, onProjectSelect, selectedProject }) => {
  const projects = [
    { id: 1, title: 'Movies' },
    { id: 2, title: 'Series' },
    { id: 3, title: 'Kids' },
    { id: 4, title: 'My lists' },
  ];

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="project-list">
      {filteredProjects.map(project => (
        <div
          key={project.id}
          className={`project-item ${selectedProject?.id === project.id ? 'selected' : ''}`}
          onClick={() => onProjectSelect(project)}
        >
          {project.title}
        </div>
      ))}
    </div>
  );
};

export default ProjectList;