export const categories = [
    { id: -1, name: 'ALL' },    
    { id: 0, name: 'HISTOR ' },
    { id: 1, name: 'FAVORITES' },
    { id: 2, name: 'SPORTS' },
    { id: 3, name: 'BIG BROTHER MZANSI' },
    { id: 4, name: 'ENTERTAINMENT' },
    { id: 5, name: 'MOVIES&SERIES' },
    { id: 6, name: 'MUSIC' },
    { id: 7, name: 'TOP POPULAR' },
    { id: 8, name: 'NEWS&LOCAL' },
    { id: 9, name: 'KIDS' },
    { id: 10, name: 'RELIGIOUS' },
    { id: 11, name: 'INTERNATIONAl' },
    
  ];
  
  export const detailsData = {
    0: [
      { id: 1, title: '分类一的详情1', desc: '这是分类一的描述1' },
      { id: 2, title: '分类一的详情2', desc: '这是分类一的描述2' },
      { id: 3, title: '分类一的详情3', desc: '这是分类一的描述3' },
      { id: 4, title: '分类一的详情4', desc: '这是分类一的描述4' },
      { id: 5, title: '分类一的详情5', desc: '这是分类一的描述5' },
      { id: 6, title: '分类一的详情6', desc: '这是分类一的描述6' },
      { id: 7, title: '分类一的详情7', desc: '这是分类一的描述7' },
      { id: 8, title: '分类一的详情8', desc: '这是分类一的描述8' },
      { id: 9, title: '分类一的详情9', desc: '这是分类一的描述9' },
      { id: 10, title: '分类一的详情10', desc: '这是分类一的描述10' },
      { id: 11, title: '分类一的详情11', desc: '这是分类一的描述11' },
      { id: 12, title: '分类一的详情12', desc: '这是分类一的描述12' },
      { id: 13, title: '分类一的详情13', desc: '这是分类一的描述13' },
      { id: 14, title: '分类一的详情14', desc: '这是分类一的描述14' },
      { id: 15, title: '分类一的详情15', desc: '这是分类一的描述15' },
      
    ],
    1: [
      { id: 3, title: '分类二的详情1', desc: '这是分类二的描述1' },
      { id: 4, title: '分类二的详情2', desc: '这是分类二的描述2' },
    ],
    2: [
      { id: 5, title: '分类三的详情1', desc: '这是分类三的描述1' },
      { id: 6, title: '分类三的详情2', desc: '这是分类三的描述2' },
    ],
    3: [
      { id: 7, title: '分类四的详情1', desc: '这是分类四的描述1' },
      { id: 8, title: '分类四的详情2', desc: '这是分类四的描述2' },
    ],
    4: [
      { id: 9, title: '分类五的详情1', desc: '这是分类五的描述1' },
      { id: 10, title: '分类五的详情2', desc: '这是分类五的描述2' },
    ],
    5: [
      { id: 11, title: '分类六的详情1', desc: '这是分类六的描述1' },
      { id: 12, title: '分类六的详情2', desc: '这是分类六的描述2' },
    ],
    6: [
      { id: 13, title: '分类七的详情1', desc: '这是分类七的描述1' },
      { id: 14, title: '分类七的详情2', desc: '这是分类七的描述2' },
    ],
    7: [
      { id: 15, title: '分类八的详情1', desc: '这是分类八的描述1' },
      { id: 16, title: '分类八的详情2', desc: '这是分类八的描述2' },
    ],
    8: [
      { id: 17, title: '分类九的详情1', desc: '这是分类九的描述1' },
      { id: 18, title: '分类九的详情2', desc: '这是分类九的描述2' },
    ],
    9: [
      { id: 19, title: '分类十的详情1', desc: '这是分类十的描述1' },
      { id: 20, title: '分类十的详情2', desc: '这是分类十的描述2' },
    ],
    10: [
      { id: 21, title: '分类十一的详情1', desc: '这是分类十一的描述1' },
      { id: 22, title: '分类十一的详情2', desc: '这是分类十一的描述2' },
    ],
    11: [
      { id: 23, title: '分类十二的详情1', desc: '这是分类十二的描述1' },
      { id: 24, title: '分类十二的详情2', desc: '这是分类十二的描述2' },
    ],
    12: [
      { id: 25, title: '分类十三的详情1', desc: '这是分类十三的描述1' },
      { id: 26, title: '分类十三的详情2', desc: '这是分类十三的描述2' },
    ], 
    13: [
      { id: 27, title: '分类十四的详情1', desc: '这是分类十四的描述1' },
      { id: 28, title: '分类十四的详情2', desc: '这是分类十四的描述2' },
    ],
    14: [
      { id: 29, title: '分类十五的详情1', desc: '这是分类十五的描述1' },
      { id: 30, title: '分类十五的详情2', desc: '这是分类十五的描述2' },
    ],
    15: [
      { id: 31, title: '分类十六的详情1', desc: '这是分类十六的描述1' },
      { id: 32, title: '分类十六的详情2', desc: '这是分类十六的描述2' },
    ],
    16: [
      { id: 33, title: '分类十七的详情1', desc: '这是分类十七的描述1' },
      { id: 34, title: '分类十七的详情2', desc: '这是分类十七的描述2' },
    ],
    17: [
        { id: 33, title: '分类十七的详情1', desc: '这是分类十七的描述1' },
        { id: 34, title: '分类十七的详情2', desc: '这是分类十七的描述2' },
      ],     
};