import React from 'react';
import './DetailSection.css';

const DetailSection = ({ details, isLoading }) => {
  return (
    <div className="detail-wrapper">
      <div className="detail-scroll">
        {isLoading ? (
          <div className="loading-state">
            加载中...
          </div>
        ) : details.length === 0 ? (
          <div className="empty-state">
            暂无详情数据
          </div>
        ) : (
          details.map(detail => (
            <div key={detail.id} className="detail-item">
              <h3>{detail.title}</h3>
              <p>{detail.desc}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DetailSection;