// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-page {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.category-header {
  padding: 15px;
  background: #000;
}

.header-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px; /* 固定工具栏高度 */
}

.history-button {
  padding: 8px 15px;
  background: #000;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 0;
}

.channel-container {
  flex: 1 1;
  display: flex;
  min-height: 0;
  background: #000;
  overflow: hidden;
}

.pagination-container {
  margin-top: 10px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  margin: 0; /* 移除所有外边距 */
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Category/Category.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY,EAAE,YAAY;AAC5B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,SAAO;EACP,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,SAAS,EAAE,YAAY;EACvB,UAAU;AACZ","sourcesContent":[".category-page {\n  height: calc(100vh - 50px);\n  display: flex;\n  flex-direction: column;\n  background-color: #000;\n}\n\n.category-header {\n  padding: 15px;\n  background: #000;\n}\n\n.header-toolbar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 34px; /* 固定工具栏高度 */\n}\n\n.history-button {\n  padding: 8px 15px;\n  background: #000;\n  border-radius: 20px;\n  font-size: 14px;\n  color: #333;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.category-content {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  padding: 15px;\n  min-height: 0;\n}\n\n.channel-container {\n  flex: 1;\n  display: flex;\n  min-height: 0;\n  background: #000;\n  overflow: hidden;\n}\n\n.pagination-container {\n  margin-top: 10px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #000;\n  margin: 0; /* 移除所有外边距 */\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
