import React from 'react';
import './HistoryModal.css';

const History = ({ onClose }) => {
  // 示例历史记录数据
  const historyItems = [
    { id: 1,  content: 'ALL' },
    { id: 2,  content: 'HISTORY' },
    { id: 3,  content: 'FAVORITES' },
    { id: 4,  content: 'SPORTS' },
    { id: 5,  content: 'BIG BROTHER MZANSI' },
    { id: 6,  content: 'ENTERTAINMENT' },
    { id: 7,  content: 'MOVIES&SERIES' },
    { id: 8,  content: 'MUSIC' },
    { id: 9,  content: 'TOP POPULAR' },
    { id: 10, content: 'NEWS & LOCAL' },
    { id: 11, content: 'KIDS' },
    { id: 12, content: 'RELIGIOUS' },
    { id: 13, content: 'INTERNATIONAL' },
  ];

  return (
    <>
      <div className="history-overlay" onClick={onClose} />
      <div className="history-panel">
        <div className="panel-header">
          <span className="header-title">Select channel</span>
          <button className="close-button" onClick={onClose}>
            <svg 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#999"
              strokeWidth="2"
              strokeLinecap="round"
            >
              <path d="M18 6L6 18" />
              <path d="M6 6L18 18" />
            </svg>
          </button>
        </div>
        <div className="panel-content">
          {historyItems.map(item => (
            <div key={item.id} className="history-item">
              <div className="history-text">{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default History;