import React, { useState } from 'react';
import './Calendar.css';

const DatePicker = ({ selectedDate, onDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatDate = (date) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}`;
  };

  const generateDateOptions = () => {
    const dates = [];
    const today = new Date();
    
    // 生成前7天的日期
    for (let i = 7; i > 0; i--) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      dates.push(date);
    }
    
    // 添加今天
    dates.push(today);
    
    // 生成后6天的日期
    for (let i = 1; i <= 6; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      dates.push(date);
    }
    
    return dates;
  };

  return (
    <>
      <div className="date-picker">
        <div className="date-picker-trigger" onClick={() => setIsOpen(true)}>
          <span className="date-text">{formatDate(selectedDate)}</span>
          <svg 
            className="arrow-icon" 
            width="12" 
            height="12" 
            viewBox="0 0 12 12"
          >
            <path 
              d="M2 4L6 8L10 4" 
              stroke="#999" 
              strokeWidth="1.5" 
              fill="none"
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      {isOpen && (
        <>
          <div className="date-picker-overlay" onClick={() => setIsOpen(false)} />
          <div className="date-picker-panel">
            <div className="panel-header">
              <span className="header-title">Select date</span>
              <button className="close-button" onClick={() => setIsOpen(false)}>
                <svg 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#999"
                  strokeWidth="2"
                  strokeLinecap="round"
                >
                  <path d="M18 6L6 18" />
                  <path d="M6 6L18 18" />
                </svg>
              </button>
            </div>
            <div className="panel-content">
              {generateDateOptions().map((date) => (
                <div
                  key={date.toISOString()}
                  className={`date-option ${
                    date.toDateString() === selectedDate.toDateString() ? 'active' : ''
                  }`}
                  onClick={() => {
                    onDateChange(date);
                    setIsOpen(false);
                  }}
                >
                  {formatDate(date)}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DatePicker;