import React from 'react';
import './MenuList.css';

const MenuList = () => {
  return (
    <div className="menu-list">
      <div className="menu-item">
        <div className="menu-icon info">i</div>
        <span>Info</span>
        <div className="arrow">›</div>
      </div>
      <div className="menu-item">
        <div className="menu-icon settings">⚙</div>
        <span>Settings</span>
        <div className="arrow">›</div>
      </div>
      <div className="menu-item">
        <div className="menu-icon login">↪</div>
        <span>Log in</span>
        <div className="arrow">›</div>
      </div>
    </div>
  );
};

export default MenuList;