// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quick-actions {
    display: flex;
    justify-content: space-around;
    margin: 20px;
    padding: 20px;
    background: #222;
    border-radius: 15px;
  }
  
  .action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .action-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .action-icon.history {
    background: #ff3333;
  }
  
  .action-icon.favorites {
    background: #ff3333;
  }
  
  .action-icon.reminders {
    background: #ff3333;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Profile/components/QuickAction/QuickAction.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".quick-actions {\n    display: flex;\n    justify-content: space-around;\n    margin: 20px;\n    padding: 20px;\n    background: #222;\n    border-radius: 15px;\n  }\n  \n  .action-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n  }\n  \n  .action-icon {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .action-icon.history {\n    background: #ff3333;\n  }\n  \n  .action-icon.favorites {\n    background: #ff3333;\n  }\n  \n  .action-icon.reminders {\n    background: #ff3333;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
