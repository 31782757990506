import { useState } from 'react';
import { categories, detailsData } from '../data/categoryData';

const useCategoryDetails = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // 获取当前分类的详情
  const getCurrentDetails = () => {
    return detailsData[activeCategory] || [];
  };

  // 获取当前分类名称
  const getCurrentCategoryName = () => {
    return categories.find(c => c.id === activeCategory)?.name;
  };

  // 处理分类切换
  const handleCategoryChange = async (categoryId) => {
    setIsLoading(true);
    setActiveCategory(categoryId);
    // 模拟加载延迟
    await new Promise(resolve => setTimeout(resolve, 300));
    setIsLoading(false);
  };

  return {
    categories,
    activeCategory,
    isLoading,
    getCurrentDetails,
    getCurrentCategoryName,
    handleCategoryChange,
  };
};

export default useCategoryDetails;