// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-page {
    height: calc(100vh - 50px); /* 减去底部导航栏的高度 */
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    overflow: hidden;
    width: 100%;
    padding: 0; /* 移除可能存在的padding */
    margin: 0; /* 移除可能存在的margin */
    overflow: hidden; /* 防止内容溢出 */
  }

  
  .message-container {
    flex: 1 1;
    display: flex;
    overflow: hidden;
  }
  
  .message-left-panel {
    width: 300px;
    border-right: 1px solid #e8e8e8;
    background: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .message-right-panel {
    flex: 1 1;
    background: #fff;
    overflow-y: auto;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Message/Message.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B,EAAE,eAAe;IAC3C,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,UAAU,EAAE,mBAAmB;IAC/B,SAAS,EAAE,kBAAkB;IAC7B,gBAAgB,EAAE,WAAW;EAC/B;;;EAGA;IACE,SAAO;IACP,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,+BAA+B;IAC/B,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":[".message-page {\n    height: calc(100vh - 50px); /* 减去底部导航栏的高度 */\n    display: flex;\n    flex-direction: column;\n    background: #f5f5f5;\n    overflow: hidden;\n    width: 100%;\n    padding: 0; /* 移除可能存在的padding */\n    margin: 0; /* 移除可能存在的margin */\n    overflow: hidden; /* 防止内容溢出 */\n  }\n\n  \n  .message-container {\n    flex: 1;\n    display: flex;\n    overflow: hidden;\n  }\n  \n  .message-left-panel {\n    width: 300px;\n    border-right: 1px solid #e8e8e8;\n    background: #fff;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .message-right-panel {\n    flex: 1;\n    background: #fff;\n    overflow-y: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
