import React from 'react';
import './ChannelSidebar.css';

const ChannelList = ({ activeChannel, onChannelChange }) => {
  const channels = [
    { id: 0, name: '频道 1' },
    { id: 1, name: '频道 2' },
    { id: 2, name: '频道 3' },
    { id: 3, name: '频道 4' },
  ];

  return (
    <div className="channel-list">
      {channels.map(channel => (
        <div
          key={channel.id}
          className={`channel-item ${activeChannel === channel.id ? 'active' : ''}`}
        >
          {channel.name}
        </div>
      ))}
    </div>
  );
};

export default ChannelList;