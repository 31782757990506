// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card {
  margin: 20px;
  background: linear-gradient(to right, #ff3333, #ff8000);
  border-radius: 15px;
  padding: 20px;
  color: white;
}

.card-content h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.expiry-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.recharge-button {
  background: white;
  color: #ff3333;
  border: none;
  border-radius: 20px;
  padding: 8px 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/components/UserCard/UserCard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uDAAuD;EACvD,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".user-card {\n  margin: 20px;\n  background: linear-gradient(to right, #ff3333, #ff8000);\n  border-radius: 15px;\n  padding: 20px;\n  color: white;\n}\n\n.card-content h2 {\n  font-size: 20px;\n  margin-bottom: 15px;\n}\n\n.expiry-info {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 15px;\n}\n\n.recharge-button {\n  background: white;\n  color: #ff3333;\n  border: none;\n  border-radius: 20px;\n  padding: 8px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
