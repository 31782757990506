// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-list {
  width: 100%;
  height: 50px;
  background: #000000;
  border-bottom: 1px solid #e8e8e8;
  display: flex; /* 改用 flex 布局更容易控制边距 */
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* 使用 max-width 和 margin 来控制整体宽度和居中 */
  max-width: 100%;
  margin: 0 auto;
  /* 使用 padding 控制两边间距 */
  padding: 0 16px;
  box-sizing: border-box;
}

.project-list::-webkit-scrollbar {
  display: none;
}

.project-item {
  /* 固定宽度，确保每个项目大小一致 */
  min-width: calc((100% - 48px) / 4); /* 总宽度减去间距(3 * 16px)后平分给4个项目 */
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  background: #000000;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  margin-right: 16px; /* 项目之间的间距 */
  transition: all 0.3s;
  color: #ffffff;
}

/* 最后一个项目不需要右边距 */
.project-item:last-child {
  margin-right: 0;
}

.project-item:hover {
  background: #e6f7ff;
}

.project-item.selected {
  background: #1890ff;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Message/components/ProjectList/ProjectList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gCAAgC;EAChC,aAAa,EAAE,sBAAsB;EACrC,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,qBAAqB;EACrB,qCAAqC;EACrC,eAAe;EACf,cAAc;EACd,sBAAsB;EACtB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,kCAAkC,EAAE,8BAA8B;EAClE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB,EAAE,YAAY;EAChC,oBAAoB;EACpB,cAAc;AAChB;;AAEA,iBAAiB;AACjB;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".project-list {\n  width: 100%;\n  height: 50px;\n  background: #000000;\n  border-bottom: 1px solid #e8e8e8;\n  display: flex; /* 改用 flex 布局更容易控制边距 */\n  align-items: center;\n  overflow-x: auto;\n  scroll-behavior: smooth;\n  scrollbar-width: none;\n  /* 使用 max-width 和 margin 来控制整体宽度和居中 */\n  max-width: 100%;\n  margin: 0 auto;\n  /* 使用 padding 控制两边间距 */\n  padding: 0 16px;\n  box-sizing: border-box;\n}\n\n.project-list::-webkit-scrollbar {\n  display: none;\n}\n\n.project-item {\n  /* 固定宽度，确保每个项目大小一致 */\n  min-width: calc((100% - 48px) / 4); /* 总宽度减去间距(3 * 16px)后平分给4个项目 */\n  height: 36px;\n  line-height: 36px;\n  border-radius: 18px;\n  background: #000000;\n  cursor: pointer;\n  white-space: nowrap;\n  text-align: center;\n  margin-right: 16px; /* 项目之间的间距 */\n  transition: all 0.3s;\n  color: #ffffff;\n}\n\n/* 最后一个项目不需要右边距 */\n.project-item:last-child {\n  margin-right: 0;\n}\n\n.project-item:hover {\n  background: #e6f7ff;\n}\n\n.project-item.selected {\n  background: #1890ff;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
