import { useState, useEffect } from 'react';
import TabBar from './components/TabBar';
import Home from './pages/Home/Home';
import Category from './pages/Category/Category';
import Message from './pages/Message/Message';
import Search from './pages/Home/components/Search/Search';
import Profile from './pages/Profile/Profile';
import './App.css';


function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showSearch, setShowSearch] = useState(false);  // 添加搜索页面状态

  useEffect(() => {
    // 监听 beforeinstallprompt 事件
    window.addEventListener('beforeinstallprompt', (e) => {
      // 阻止 Chrome 67 及更早版本自动显示安装提示
      e.preventDefault();
      // 保存安装提示事件
      setDeferredPrompt(e);
      // 显示安装按钮
      setShowInstallButton(true);

      // 10秒后自动关闭提示
      const timer = setTimeout(() => {
        setShowInstallButton(false);
        setDeferredPrompt(null);
      }, 1000);

      //清理定时器
      return () => clearTimeout(timer);
    });

    // 监听安装完成事件
    window.addEventListener('appinstalled', () => {
      // 隐藏安装按钮
      setShowInstallButton(false);
      // 清除提示事件
      setDeferredPrompt(null);
      console.log('PWA 已成功安装');
    });
  }, []);

  // 处理安装点击事件
  const handleInstallClick = async () => {
    if (!deferredPrompt) {
      return;
    }
    // 显示安装提示
    deferredPrompt.prompt();
    // 等待用户操作
    const { outcome } = await deferredPrompt.userChoice;
    console.log(`用户操作结果: ${outcome}`);
    // 使用完提示后，清除它
    setDeferredPrompt(null);
    // 隐藏安装按钮
    setShowInstallButton(false);
  };

  const handleClosePrompt = () => {
    setShowInstallButton(false);
  };

  const renderContent = () => {
    if (showSearch) {
      return <Search onBack={() => setShowSearch(false)} />;
    }

    switch (activeTab) {
      case 0:
        return <Home onSearchClick={() => setShowSearch(true)} />; 
      case 1:
        return <Category />;
      case 2:
        return <Message />;
      case 3:
        return <Profile />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="App">
      {renderContent()}
      
      {showInstallButton && (
        <div className="install-prompt">
          <button className="close-button" onClick={handleClosePrompt}>×</button>
          <p>安装此应用以获得更好的体验！</p>
          <button onClick={handleInstallClick} className="install-button">
            立即安装
          </button>
        </div>
      )}
      
      <TabBar activeTab={activeTab} onTabChange={setActiveTab} />
    </div>
  );
}

export default App;