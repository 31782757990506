// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advertisement {
    margin: 20px;
  }
  
  .ad-image {
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Profile/components/Advertisement/Advertisement.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,iBAAiB;EACnB","sourcesContent":[".advertisement {\n    margin: 20px;\n  }\n  \n  .ad-image {\n    width: 100%;\n    border-radius: 15px;\n    object-fit: cover;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
