import SearchBar from './components/SearchBar/SearchBar';
import AppName from './components/AppName/AppName';
import VideoSection from './components/VideoSection/VideoSection_hls';
import CategoryList from './components/CategoryList/CategoryList';
import DetailSection from './components/DetailSection/DetailSection';
import useCategoryDetails from './hooks/useCategoryDetails';
import './Home.css';

const Home = ( {onSearchClick} ) => {

  const {
    categories,
    activeCategory,
    isLoading,
    getCurrentDetails,
    getCurrentCategoryName,
    handleCategoryChange,
  } = useCategoryDetails();

  return (
    <div className="home-container">
      {/* 搜索框组件 */}
      <SearchBar onSearchClick={onSearchClick} />
      {/* 应用名称组件 */}
      <AppName name="LIVE TV" />
      {/* 视频组件 */}
      <div className="video-wrapper">
      <VideoSection 
          streamUrl="http://144.202.26.47:25461/live/mm/mm/4.m3u8"
        />
      </div>
      {/* 内容区域 */}
      <div className="content-section">
        {/* 分类列表组件 */}
        <CategoryList 
          categories={categories}
          activeCategory={activeCategory}
          onCategoryChange={handleCategoryChange}
        />
        {/* 详情组件 */}
        <DetailSection 
          details={getCurrentDetails()}
          categoryName={getCurrentCategoryName()}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Home;