// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-panel {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50vh;
  background: #000;
  z-index: 1001;
  animation: slideUp 0.3s ease-out;
  display: flex;          /* 添加 flex 布局 */
  flex-direction: column; /* 垂直方向排列 */
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #333;
}

.panel-content {
  flex: 1 1;             /* 改用 flex: 1 替代固定高度 */
  overflow-y: auto;
  padding: 16px;
}

.history-item {
  padding: 16px;
  border-bottom: 1px solid #333;
}

.history-text {
  font-size: 16px;
  color: #ffffff;
}

.history-item:last-child {
  border-bottom: none;
}

.close-button:hover svg {
  stroke: #fff;  /* 悬停时图标变白 */
}`, "",{"version":3,"sources":["webpack://./src/pages/Category/components/HistoryModal/HistoryModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,gCAAgC;EAChC,aAAa,WAAW,eAAe;EACvC,sBAAsB,EAAE,WAAW;AACrC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,SAAO,cAAc,sBAAsB;EAC3C,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY,GAAG,YAAY;AAC7B","sourcesContent":[".history-panel {\n  position: fixed;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  height: 50vh;\n  background: #000;\n  z-index: 1001;\n  animation: slideUp 0.3s ease-out;\n  display: flex;          /* 添加 flex 布局 */\n  flex-direction: column; /* 垂直方向排列 */\n}\n\n.panel-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px;\n  border-bottom: 1px solid #333;\n}\n\n.panel-content {\n  flex: 1;             /* 改用 flex: 1 替代固定高度 */\n  overflow-y: auto;\n  padding: 16px;\n}\n\n.history-item {\n  padding: 16px;\n  border-bottom: 1px solid #333;\n}\n\n.history-text {\n  font-size: 16px;\n  color: #ffffff;\n}\n\n.history-item:last-child {\n  border-bottom: none;\n}\n\n.close-button:hover svg {\n  stroke: #fff;  /* 悬停时图标变白 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
