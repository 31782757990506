// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-name-section {
    padding: 12px 15px;
    background-color: #000;
    border-bottom: 1px solid #333;
  }
  
  .app-name {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: left;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/AppName/AppName.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,6BAA6B;EAC/B;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,gBAAgB;EAClB","sourcesContent":[".app-name-section {\n    padding: 12px 15px;\n    background-color: #000;\n    border-bottom: 1px solid #333;\n  }\n  \n  .app-name {\n    font-size: 20px;\n    font-weight: bold;\n    color: #fff;\n    text-align: left;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
