import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, onPageChange, hasMore }) => {
  return (
    <div className="pagination">
      <button 
        className="pagination-button"
        disabled={currentPage <= 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Previous page
      </button>
      <button 
        className="pagination-button"
        disabled={!hasMore}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next page
      </button>
    </div>
  );
};

export default Pagination;