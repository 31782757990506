import React, { useState } from 'react';
import Calendar from './components/Calendar/Calendar';
import HistoryModal from './components/HistoryModal/HistoryModal';
import ChannelSidebar from './components/ChannelSidebar/ChannelSidebar';
import ChannelContent from './components/ChannelContent/ChannelContent';
import Pagination from './components/Pagination/Pagination';
import './Category.css';

const Category = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showHistory, setShowHistory] = useState(false);
  const [activeChannel, setActiveChannel] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="category-page">
      <div className="category-header">
        <div className="header-toolbar">
          <Calendar 
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
          />
<div className="history-button" onClick={() => setShowHistory(!showHistory)}>
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" stroke="#fff" strokeWidth="2"/>
    <path 
      d="M8 9H16" 
      stroke="#fff" 
      strokeWidth="2" 
      strokeLinecap="round"
    />
    <path 
      d="M8 12H16" 
      stroke="#fff" 
      strokeWidth="2" 
      strokeLinecap="round"
    />
    <path 
      d="M8 15H16" 
      stroke="#fff" 
      strokeWidth="2" 
      strokeLinecap="round"
    />
  </svg>
</div>
        </div>
      </div>

      {/* 主要内容区域 */}
      <div className="category-content">
        <div className="channel-container">
          <ChannelSidebar
            activeChannel={activeChannel}
            onChannelChange={setActiveChannel}
          />
          <ChannelContent channelId={activeChannel} />
        </div>
        
        {/* 在 channel-container 下方添加分页 */}
        <div className="pagination-container">
        <Pagination 
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      </div>

      {showHistory && (
        <HistoryModal onClose={() => setShowHistory(false)} />
      )}
    </div>
  );
};

export default Category;