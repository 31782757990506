// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .profile-header h1 {
    font-size: 24px;
    font-weight: normal;
  }
  
  .notification-icon {
    position: relative;
  }
  
  .notification-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Profile/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,UAAU;IACV,WAAW;IACX,qBAAqB;IACrB,kBAAkB;EACpB","sourcesContent":[".profile-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 20px;\n  }\n  \n  .profile-header h1 {\n    font-size: 24px;\n    font-weight: normal;\n  }\n  \n  .notification-icon {\n    position: relative;\n  }\n  \n  .notification-dot {\n    position: absolute;\n    top: 0;\n    right: 0;\n    width: 8px;\n    height: 8px;\n    background-color: red;\n    border-radius: 50%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
