import React from 'react';
import './UserCard.css';

const UserCard = () => {
  return (
    <div className="user-card">
      <div className="card-content">
        <h2>LOGIN IN/SIGN UP</h2>
        <p>Device User</p>
        <p>Simultaneous screens:2-device</p>
        <div className="expiry-info">
          <span>Expiry Date : 01/04/2025</span>
          <button className="recharge-button">Recharge</button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;