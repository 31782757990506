import React, { useState } from 'react';
import MessageSearch from './components/MessageSearch/MessageSearch';
import ProjectList from './components/ProjectList/ProjectList';
import ProjectDetail from './components/ProjectDetail/ProjectDetail';
import './Message.css';

const Message = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedProject, setSelectedProject] = useState({ id: 1, title: 'Movies' });

  // 处理搜索
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // 处理项目选择
  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  return (
    <div className="message-page">
      <MessageSearch onSearch={handleSearch} />
      <ProjectList 
        searchText={searchText}
        onProjectSelect={handleProjectSelect}
        selectedProject={selectedProject}
      />
      <ProjectDetail project={selectedProject} />
    </div>
  );
};

export default Message;