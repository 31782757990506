import React from 'react';
import './CategoryList.css';

const CategoryList = ({ categories, activeCategory, onCategoryChange }) => {
  return (
    <div className="category-wrapper">
      <div className="category-scroll">
      {categories.map(category => ( 
        <div 
          key={category.id}
          className={`category-item ${activeCategory === category.id ? 'active' : ''}`}
          onClick={() => onCategoryChange(category.id)}
        >
          {category.name}
        </div>
      ))}
    </div>
    </div>
  );
};

export default CategoryList;